<template>
  <div>
    <div class="grid grid-cols-12 gap-0 mb-3">
      <dl class="col-span-12 lg:col-span-6">
        <DataList :gray="false" :dt="'Permohonan'">
          <template #dd>
            {{ detail.tagihan?.jenis_pengajuan?.nm_jenis_pengajuan }}
          </template>
        </DataList>
        <DataList :gray="false" :dt="'Semester'">
          <template #dd>
            {{ detail.tagihan?.smt?.nm_smt }}
          </template>
        </DataList>
        <DataList :gray="true" :dt="'Biaya'">
          <template #dd>
            <b> {{ $h.IDR(detail.tagihan?.biaya) }}</b>
          </template>
        </DataList>
      </dl>

      <dl class="col-span-12 lg:col-span-6">
        <DataList
          :gray="true"
          :dd="detail.tagihan?.nm_pd"
          :dt="'Nama'"
        ></DataList>
        <DataList
          :gray="false"
          :dd="detail.tagihan?.npm"
          :dt="'NPM'"
        ></DataList>
        <DataList :gray="true" :dt="'Nominal Tagih'">
          <template #dd>
            <b> {{ $h.IDR(parseFloat(detail.tagihan?.biaya) + 3000) }}</b>
          </template>
        </DataList>
      </dl>
    </div>

    <div class="grid grid-cols-12 gap-3 p-3">
      <div class="col-span-12 xl:col-span-5 p-3">
        <label class="form-label">Tahun Keuangan</label>
        <select v-model="model.id_smt" class="form-select">
          <option
            v-for="(item, index) in listOptionsState?.semester"
            :key="index"
            :value="item.id"
          >
            {{ item.text }}
          </option>
        </select>
        <label class="form-label mt-3">Jenis Pembiayaan</label>
        <TailSelect
          v-model="id_jenis_biaya"
          :options="{
            search: true,
            classNames: 'w-full',
            deselect: true,
          }"
        >
          <option
            v-for="list in jenisBiayaList?.filter((a) => a.a_permohonan === 1)"
            :key="list.id"
            :value="list.id"
            :selected="list.id == id_jenis_biaya"
          >
            {{ list.nama }} ({{ list.id }})
          </option>
        </TailSelect>
      </div>

      <div class="col-span-12 xl:col-span-7 bg-gray-300 dark:bg-gray-700 p-3">
        <div class="mb-3">
          <div class="grid grid-cols-2 gap-2">
            <div class="col-span-1">
              <label class="form-label">Sub Comp</label>
              <TailSelect
                v-model="model.subcomp"
                :options="{
                  search: true,
                  classNames: 'w-full',
                  deselect: true,
                }"
              >
                <option
                  v-for="list in subcomps"
                  :key="list.Kode"
                  :value="list.Kode"
                >
                  {{ list.Keterangan }} ({{ list.Kode }})
                </option>
              </TailSelect>
            </div>
            <div class="col-span-1">
              <label class="form-label">Produk</label>

              <TailSelect
                v-model="model.produk"
                :options="{
                  search: true,
                  classNames: 'w-full',
                  deselect: true,
                }"
              >
                <option
                  v-for="list in produks"
                  :key="list.kode"
                  :value="list.kode"
                >
                  {{ list.Keterangan }} ({{ list.kode }})
                </option>
              </TailSelect>
            </div>
          </div>

          <label class="form-label mt-3">Bank</label>
          <select v-model="model.bank" class="form-select">
            <option
              v-for="(item, index) in banks"
              :key="index"
              :value="item.kode"
            >
              {{ item.Keterangan }}
            </option>
          </select>
        </div>

        <button
          type="button"
          class="btn btn-success w-50 mr-5"
          @click="buatTagihan"
        >
          Buat Tagihan
        </button>
      </div>
    </div>
    <div class="mt-3">
      <Alert
        v-if="statusSimpan.status != null"
        :type="statusSimpan.status ? 'alert-success' : 'alert-danger'"
        :dismissable="true"
        >{{ statusSimpan.msg }}</Alert
      >
    </div>
    <ModalDialogPositive
      :id="'buat-tagihan'"
      :question="'Anda yakin ingin membuat Tagihan untuk mahasiswa?'"
    >
      <template #delete-button>
        <button type="button" class="btn btn-success w-24" @click="submit">
          Ya
        </button>
      </template>
    </ModalDialogPositive>
  </div>
</template>

<script>
import Alert from "../../components/alerts/Alert";
import { HTTPKEU } from "../../services/http";
import { option } from "@/utils/listoptions";
import ModalDialogPositive from "../../components/utility/ModalDialogPositive";
import DataList from "@/components/table-and-list/DataList";
export default {
  name: "FormBuatTagihanPermohonan",
  components: {
    DataList,
    ModalDialogPositive,
    Alert,
  },
  props: {
    detail: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      smt: { a_periode_aktif: 0 },
      smtSelected: null,
      subcomps: null,
      banks: null,
      produks: null,
      jenisBiayaList: null,
      id_jenis_biaya: 15,
      jenisBiayaSelected: [],
      model: {
        bank: "BCA",
        produk: null,
        subcomp: null,
        id_smt: null,
      },
      statusSimpan: {
        msg: null,
        status: null,
      },
    };
  },
  computed: {
    listOptionsState() {
      return this.$store.state.main.listOptions;
    },
  },
  watch: {
    detail: {
      handler: function (h) {
        this.model.id_smt = h?.tagihan?.id_smt;
      },
      immediate: true,
    },
  },
  beforeMount() {
    option.fetchSemester();
    this.getBankList();
    this.getProdukList();
    this.getSubcomList();
    this.getJenisBiayaList();
  },
  methods: {
    selectSemester(curr, id) {
      this.smtSelected = id;
      this.smt = curr[0];
    },
    getBankList() {
      HTTPKEU.get("list/bank").then((res) => {
        this.banks = res.data;
      });
    },
    getJenisBiayaList() {
      HTTPKEU.get("list/jenisbiaya").then((res) => {
        this.jenisBiayaList = res.data;
      });
    },
    getSubcomList() {
      HTTPKEU.get("list/subcomp").then((res) => {
        this.subcomps = res.data;
      });
    },
    getProdukList() {
      HTTPKEU.get("list/produk").then((res) => {
        this.produks = res.data;
      });
    },
    buatTagihan() {
      cash("#buat-tagihan").modal("show");
    },
    submit() {
      this.statusSimpan.msg = null;
      this.statusSimpan.status = null;
      if (this.model.subcomp != null || this.model.produk != null) {
        this.loading = true;

        HTTPKEU.post("permohonanakademik/simpan_tagihan", {
          subcomp: this.model.subcomp,
          bank: this.model.bank,
          produk: this.model.produk,
          data_mhs: this.detail?.tagihan,
          id_smt: this.model.id_smt,
          id_jenis_biaya: this.id_jenis_biaya,
        })
          .then((res) => {
            if (res.status === 200) {
              this.statusSimpan.msg = res.data?.message;
              this.statusSimpan.status = true;
            } else {
              this.statusSimpan.msg = res.data?.message;
              this.statusSimpan.status = false;
            }
            this.$emit("buatTagihan", true);
          })
          .catch((err, d) => {
            console.log(err.response, d);
            this.statusSimpan.msg = err.response?.data?.message
              ? err.response?.data?.message
              : err.message;
            this.statusSimpan.status = false;
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        this.statusSimpan.msg = "Pilih SubComp dan Produk terlebih dahulu";
        this.statusSimpan.status = false;
      }

      cash("#buat-tagihan").modal("hide");
    },
  },
};
</script>

<style scoped></style>
