<template>
  <div class="flex flex-col sm:flex-row">
    <div class="flex-initial pt-1 mr-5 font-medium text-lg">Cari Mahasiswa</div>
    <div class="flex-1 input-form">
      <div class="p-fluid">
        <AutoComplete
          v-model="selectedMahasiswa"
          :suggestions="filteredMahasiswa"
          field="label"
          force-selection
          placeholder="Ketikkan Nama atau NPM Mahasiswa"
          class="w-auto remake"
          @focus="loadDataMahasiswa"
          @complete="searchMahasiswa($event)"
        >
          <template #item="slotProps">
            <div class="item uppercase">
              <div>
                <strong> {{ slotProps.item?.nama }}</strong>
                {{ slotProps.item?.npm }}
              </div>
            </div>
          </template>
        </AutoComplete>
      </div>
    </div>

    <div v-if="showBtn" class="flex-initial">
      <button
        class="btn btn-success mt-2 sm:ml-2 sm:mt-0"
        :disabled="isDisabled"
        @click="cariMahasiswa"
      >
        <slot name="button"></slot>
      </button>
    </div>
  </div>
</template>

<script>
import { mahasiswa } from "@/services/models";
import { userdata } from "@/utils/userdata";

export default {
  name: "CariMahasiswa",
  props: {
    npm: {
      type: String,
      default: "",
    },
    showBtn: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      listMahasiswa: null,
      filteredMahasiswa: null,
      selectedMahasiswa: [],
    };
  },
  computed: {
    isDisabled: function () {
      return !this.selectedMahasiswa?.id_reg_pd;
    },
  },
  watch: {
    npm: {
      handler: function (h) {
        if (h?.length > 0) {
          this.searchMahasiswa({ query: h });
        }
      },
      immediate: true,
    },
    selectedMahasiswa: {
      handler: function (h) {
        if (h?.id_reg_pd && !this.showBtn) {
          this.$emit("dataMahasiswa", h);
        }
      },
      immediate: true,
    },
  },
  mounted() {},
  methods: {
    searchMahasiswa(event) {
      setTimeout(() => {
        if (!event.query.length) {
          this.filteredMahasiswa = [...this.listMahasiswa];
        } else {
          mahasiswa.RegistasiPD.searchData({
            params: {
              q: event.query,
            },
          }).then((result) => {
            this.listMahasiswa = result.data.data.map((mhs) => {
              return {
                id_reg_pd: mhs.id_reg_pd,
                id_pd: mhs.peserta_didik?.id_pd,
                nama: mhs.peserta_didik?.nm_pd,
                npm: mhs.nipd,
                mhs: mhs,
                label: mhs.peserta_didik?.nm_pd + " (" + mhs.nipd + ")",
              };
            });
            this.filteredMahasiswa = [...this.listMahasiswa];
          });
        }
      }, 100);
    },
    cariMahasiswa() {
      this.$emit("dataMahasiswa", this.selectedMahasiswa);
    },
    loadDataMahasiswa() {
      if (!userdata.isAuthenticated())
        mahasiswa.RegistasiPD.searchData({}).then((result) => {
          this.listMahasiswa = result?.data?.data?.map((mhs) => {
            return {
              id_reg_pd: mhs.id_reg_pd,
              id_pd: mhs.peserta_didik?.id_pd,
              nama: mhs.peserta_didik?.nm_pd,
              npm: mhs.nipd,
              mhs: mhs,
              label: mhs.peserta_didik?.nm_pd + " (" + mhs.nipd + ")",
            };
          });
        });
    },
    clearSelectedMahasiswa() {
      this.selectedMahasiswa = [];
      console.log("Selected Mahasiswa Cleared");
    },
  },
};
</script>

<style></style>
