<template>
  <div>
    <div v-if="loading" class="block-loading">
      <LoadingIcon icon="three-dots" class="w-8 h-8" />
      <div class="text-center text-xs mt-2">Loading... Please Wait...</div>
    </div>
    <div class="grid grid-cols-12 gap-0 mb-3">
      <dl class="col-span-12 lg:col-span-6">
        <DataList :gray="false" :dt="'TA (Semester)'">
          <template #dd>
            {{ detail.tagihan?.TahunAkademik }} ({{
              $h.semester(detail.tagihan?.KD_Semester)
            }})
          </template>
        </DataList>
        <DataList
          :gray="true"
          :dd="detail.tagihan?.SubComp"
          :dt="'Sub Comp'"
        ></DataList>
        <DataList
          :gray="false"
          :dd="
            detail.tagihan?.NamaProduk + ' (' + detail.tagihan?.KodeProduk + ')'
          "
          :dt="'Produk'"
        ></DataList>

        <DataList
          :gray="true"
          :dd="detail.tagihan?.TGLUPLOAD"
          :dt="'Tanggal Penagihan'"
        ></DataList>
        <DataList :gray="false" :dd="null" :dt="'Admin Penagih'"></DataList>
        <DataList
          :gray="true"
          :dd="
            detail.tagihan?.TGLTRANSAKSI
              ? detail.tagihan?.TGLTRANSAKSI
              : detail.tagihan?.Tgl_Transaksi
          "
          :dt="'Tanggal Transaksi'"
        ></DataList>
        <DataList :gray="false" :dd="null" :dt="'Admin Validator'"></DataList>
        <DataList
          :gray="true"
          :dd="
            detail.tagihan?.Validasi == 'Y'
              ? detail.tagihan?.Waktu_Transaksi
              : ''
          "
          :dt="'Tanggal Validasi'"
        ></DataList>
      </dl>

      <dl class="col-span-12 lg:col-span-6">
        <DataList
          :gray="true"
          :dd="detail.tagihan?.Nama + ' (' + detail.tagihan?.NPM + ')'"
          :dt="'Nama (NPM)'"
        ></DataList>
        <DataList
          :gray="false"
          :dd="detail.tagihan?.NO_VA"
          :dt="'No. VA'"
        ></DataList>
        <DataList
          :gray="true"
          :dd="detail.tagihan?.NO_UPLOAD"
          :dt="'Nomor Upload'"
        ></DataList>

        <DataList
          :gray="false"
          :dd="$h.IDR(detail.tagihan?.Tagihan)"
          :dt="'Nominal Tagihan'"
        ></DataList>

        <DataList
          :gray="true"
          :dd="$h.IDR(detail.tagihan?.Jlh_Transaksi)"
          :dt="'Nominal Bayar'"
        ></DataList>
        <DataList
          :gray="false"
          :dd="detail.tagihan?.MLPOREF"
          :dt="'ID Transaksi BANK'"
        ></DataList>
        <DataList
          :gray="true"
          :dd="detail.tagihan?.Add_Info"
          :dt="'Info Tambahan'"
        >
          <template
            v-if="
              statusValidasi?.msg?.ErrorCode == 'ESB-99-132' ||
              statusValidasi?.msg?.ErrorCode == 'ESB-99-039'
            "
            #dd
          >
            <select
              v-if="detail.tagihan.Validasi == 'T'"
              v-model="model.infoTambahan"
              class="form-select w-full"
            >
              <option value="x">Melewati Waktu Transaksi H-2</option>
              <option value="Via Rekening Yayasan">
                Melalui Rekening Yayasan
              </option>
              <option value="Force Pay">Force Pay</option>
            </select>
            <div v-if="model.infoTambahan != 'x'" class="mt-2">
              <label class="">Tanggal Bayar</label>
              <input
                v-model="model.tglBayar"
                type="date"
                placeholder="Tanggal Bayar"
                class="form-control"
              />
            </div>
          </template>
        </DataList>
      </dl>
    </div>
    <div
      v-if="$u.isSuperAdmin() || $u.isKepalaUnit()"
      class="grid grid-cols-12 gap-1 mb-3"
    >
      <div class="col-span-6">
        <button
          class="btn btn-primary btn-sm"
          @click="
            cekTransaksiBank(detail.tagihan?.KodeBank, detail.tagihan?.MLPOREF)
          "
        >
          <battery-charging-icon class="mr-2"></battery-charging-icon> Cek
          Transaksi BANK
        </button>

        <button
          class="btn btn-success btn-sm ml-2"
          :disabled="!allowValidasi"
          @click="validasi"
        >
          <check-circle-icon class="mr-2"></check-circle-icon>
          Validasi
        </button>

        <button
          v-if="
            statusValidasi?.msg?.ErrorCode == 'ESB-99-132' ||
            statusValidasi?.msg?.ErrorCode == 'ESB-99-039'
          "
          class="btn btn-danger btn-sm ml-2"
          @click="validasi"
        >
          <check-circle-icon class="mr-2"></check-circle-icon>
          Force Validasi
        </button>
      </div>
      <div class="col-span-4"></div>
      <div class="col-span-4"></div>
    </div>
    <Alert
      v-if="$u.isAdminStaff() && !$u.isSuperAdmin()"
      :type="statusValidasi?.status ? 'alert-success' : 'alert-danger'"
    >
      <div>
        Maaf anda tidak dapat melakukan validasi, silahkan hubungi Kepala Unit
      </div>
    </Alert>

    <Alert
      v-if="statusValidasi?.status != null"
      :type="statusValidasi?.status ? 'alert-success' : 'alert-danger'"
      :dismissable="true"
      @closeAndClear="clearV"
    >
      <div v-if="statusValidasi?.msg?.ErrorCode">
        Transaksi Bank dapat ditemukan, pastikan Mahasiswa telah melakukan
        pembayaran melalui VA maupun Rekening Yayasan
      </div>
      <div v-else>
        {{ statusValidasi?.msg }}
      </div>
    </Alert>
    <div v-if="transaksiBank != null" class="mb-2">
      <dl>
        <DataList
          :gray="true"
          :dd="transaksiBank[0].PaymentFlagStatus"
          :dt="'Payment Status'"
        ></DataList>
        <DataList
          :gray="true"
          :dd="transaksiBank[0].RequestID"
          :dt="'Request ID'"
        ></DataList>
        <DataList
          :gray="true"
          :dd="transaksiBank[0].Reference"
          :dt="'Reference'"
        ></DataList>
        <DataList
          :gray="true"
          :dd="$h.IDR(transaksiBank[0].TotalAmount)"
          :dt="'Total Amount'"
        ></DataList>
        <DataList
          :gray="true"
          :dd="$h.IDR(transaksiBank[0].PaidAmount)"
          :dt="'Paid Amount'"
        >
        </DataList>
        <DataList
          :gray="true"
          :dd="transaksiBank[0].TransactionDate"
          :dt="'Transaction Date'"
        ></DataList>
      </dl>
    </div>

    <Alert>
      Mendapatkan notifikasi status transaksi transfer dana ke VA yang sudah
      selesai di proses oleh bank. Data akan tersedia antara hari H (hari H)
      sampai dengan hari H-2 (H-2 / lusa kemarin).
    </Alert>
  </div>
</template>

<script>
import { HTTPKEU } from "@/services/http";
import axios from "axios";
import Alert from "../../../components/alerts/Alert";
import DataList from "./DataList";
export default {
  name: "DetailTransaksi",
  components: { DataList, Alert },
  props: {
    detail: {
      type: Object,
      default: null,
    },
    process: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      transaksiBank: null,
      allowValidasi: false,
      statusValidasi: {
        msg: null,
        status: null,
      },
      loading: false,
      model: { infoTambahan: "x", tglBayar: "" },
    };
  },
  watch: {
    detail: {
      handler: function (h) {
        this.transaksiBank = null;
        this.allowValidasi = false;
      },
      immediate: true,
    },
    process: {
      handler: function (h) {
        if (h == false) {
          this.transaksiBank = null;
          this.allowValidasi = false;
        }
      },
      immediate: true,
    },
  },
  methods: {
    getSubcomList() {
      HTTPKEU.get("master/subcomp").then((res) => {
        this.subcomps = res.data;
      });
    },
    getProdukList() {
      HTTPKEU.get("master/produk").then((res) => {
        this.produks = res.data;
      });
    },
    cekTransaksiBank(bank, idtrx) {
      this.loading = true;
      this.transaksiBank = null;
      this.allowValidasi = false;
      var data = new FormData();
      data.append("id", idtrx);

      var config = {
        method: "post",
        url: "https://sw.mdp.net.id/bill",
        headers: {
          Accept: "application/json",
        },
        data: data,
      };
      let that = this;
      axios(config)
        .then(function (res) {
          if (res.status === 200) {
            that.statusValidasi.status =
              res.data?.error?.code == 0 ? true : false;
            that.statusValidasi.msg =
              res.data?.error?.code == 0
                ? "Transaksi BANK ditemukan!"
                : res.data?.error?.msg;
            if (res.data?.error?.code == "0") {
              that.transaksiBank = res.data?.data?.TransactionData;
              that.allowValidasi = true;
            }
          } else {
            that.statusValidasi.status = false;
            that.statusValidasi.msg = res.message;
          }
        })
        .catch(function () {
          that.statusValidasi.status = false;
          that.statusValidasi.msg = "Tejadi error pada API Payment Gateway";
        })
        .finally(() => {
          this.loading = false;
        });
    },
    clearV() {
      this.statusValidasi.status = null;
      this.statusValidasi.msg = null;
    },
    validasi() {
      this.$emit("validasiTransaksi", this.transaksiBank, this.model);
    },
  },
};
</script>

<style scoped></style>
